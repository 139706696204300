@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&family=Ubuntu:wght@400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Ubuntu;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
    html {font-family: Ubuntu}
}